import { useEffect, useState } from "react";
import UserEnquiry from "./UserEnquiry";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { purpleGradient } from "constants/css";

const title_style = `p-3 font-bold text-xl mb-2 cursor-pointer hover:bg-black hover:text-white rounded-xl`;
const detail_style = `p-3 bg-gradient-to-r ${purpleGradient} text-white font-bold mb-4 rounded-xl`;

export default function ChatProcessing({ selectedEnquiry }) {
    const [processing, setProcessing] = useState({});
    const [visibleDetails, setVisibleDetails] = useState({});
    const [processingToggle, setProcessingToggle] = useState(true);

    useEffect(() => {
        if (selectedEnquiry && selectedEnquiry.processing) {
            setProcessing(selectedEnquiry.processing);
            const initialVisible = {};
            Object.keys(selectedEnquiry.processing).forEach((key) => {
                initialVisible[key] = true; // Initially show all details
            });
            initialVisible["Timestamp"] = true;
            initialVisible["Chatbot Response"] = true;
            initialVisible["Processing"] = true;
            setVisibleDetails(initialVisible);
        } else {
            setProcessing({});
            setVisibleDetails({});
            setProcessingToggle(true);
        }
    }, [selectedEnquiry]);

    // Function to handle newline characters in the context string
    const formatText = (text) => {
        if (typeof text === "string") {
            return text.split("\n").map((line, index) => (
                <span key={index}>
                    {line}
                    <br />
                </span>
            ));
        }
        return text;
    };

    const toggleVisibility = (key) => {
        setVisibleDetails((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
        if (key === "Processing") {
            setProcessingToggle(!processingToggle);
        }
    };

    const DetailSection = ({ title, detail, isVisible }) => (
        <div>
            <h1 className={title_style} onClick={() => toggleVisibility(title)}>
                {isVisible ? (
                    <KeyboardArrowDownIcon />
                ) : (
                    <KeyboardArrowRightIcon />
                )}
                {title}
            </h1>
            {isVisible && (
                <p className={detail_style}>
                    {detail === "" ? "No Data Found" : formatText(detail)}
                </p>
            )}
        </div>
    );

    return (
        <>
            <div className="h-full w-full flex flex-col p-2 overflow-y-auto">
                <div className="text-center text-2xl font-bold mb-5 shrink-0">
                    Chat Processing
                </div>

                <div className="flex flex-col border-[1px] border-black rounded-xl p-4 h-full drop-shadow-sm overflow-y-auto">
                    {selectedEnquiry ? (
                        <>
                            <div className="flex flex-row">
                                <UserEnquiry
                                    enquiry={selectedEnquiry.enquiry}
                                />
                            </div>

                            <div className="border-[1px] border-black rounded-md p-4 mt-5 bg-gray-200 h-full drop-shadow-md overflow-y-auto break-words text-wrap	">
                                {processing &&
                                Object.keys(processing).length > 0 ? (
                                    <>
                                        <DetailSection
                                            title="Timestamp"
                                            detail={selectedEnquiry.timestamp}
                                            isVisible={
                                                visibleDetails["Timestamp"]
                                            }
                                        />

                                        <DetailSection
                                            title="Chatbot Response"
                                            detail={selectedEnquiry.response}
                                            isVisible={
                                                visibleDetails[
                                                    "Chatbot Response"
                                                ]
                                            }
                                        />

                                        <DetailSection
                                            title="Processing"
                                            detail="Metadata of Chatbot Response Will be Displayed Here"
                                            isVisible={
                                                visibleDetails["Processing"]
                                            }
                                        />
                                        {processingToggle &&
                                            Object.entries(processing).map(
                                                ([key, value]) => (
                                                    <DetailSection
                                                        key={key}
                                                        title={key}
                                                        detail={value}
                                                        isVisible={
                                                            visibleDetails[key]
                                                        }
                                                        className="pl-4"
                                                    />
                                                )
                                            )}
                                    </>
                                ) : (
                                    <p>No Data Available</p>
                                )}
                            </div>
                        </>
                    ) : (
                        <div className="flex justify-center items-center text-center w-full h-full font-bold text-xl px-10">
                            Please Select Any Of The User Message to View The
                            Chatbot Processing
                        </div>
                    )}
                    {/* /* enquiry_id, conversation_id, enquiry, timestamp, chatbot_response_id, chatbot_id, response, processing} */}
                </div>
            </div>
        </>
    );
}
