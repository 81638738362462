import React from 'react';

function SpinnerEffect() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-gray-900 bg-opacity-50 z-50 backdrop-blur-sm">
      <div className="animate-spin h-12 w-12 border-t-4 rounded-full border-white text-md mb-3"></div>
      <h1 className="text-white mt-4 text-md">Loading... Please Wait</h1>
    </div>
  );
}

export default SpinnerEffect;