import { Link } from "react-router-dom";

export default function SidebarItem({
    to,
    name,
    icon,
    alt,
    customClass,
    onClick
}){
    return (
    <Link to={to}>
        <div className={`text-base flex items-center px-4 py-4 rounded-md text-gray-600 hover:bg-black hover:text-white ` + customClass} onClick={onClick}>
                <div className="flex justify-left items-center h-full w-full">
                    {icon}
                    <div className="">{name}</div>
                </div>
        </div>
    </Link>)
}