import { purpleGradient } from 'constants/css'
import React from 'react'

export default function BotResponse({response, display = "right", purpleMode = true}) {

  const botIcon = <img src={require('assets/images/bot.png')} alt="bot" className="w-6 h-6 rounded-full" />;
  
  return (
    <>
      {
        display=="right" ?

        <div className='flex flex-row justify-end gap-3 mb-6 '>

          <div className={`p-3 rounded-md ml-2 border-gray-300 border-[1px] drop-shadow-lg flex-shrink-1 text-white ${purpleMode == true ? "border-white bg-gradient-to-r " + purpleGradient : "bg-black"}`}>
            {response}
          </div>

          <div className='flex justify-center items-center w-7 h-7 p-1 rounded-full border-[1px] border-black shadow-2xl flex-shrink-0'>
            {botIcon}
          </div>

        </div>

        : 

        <div className='flex flex-row justify-start gap-3 mb-4'>
          <div className='flex justify-center items-center w-7 h-7 p-1 rounded-full border-[1px] border-black shadow-2xl flex-shrink-0'>
              {botIcon}
          </div>

          <div className={`p-3 rounded-md ml-2 border-gray-300 border-[1px] drop-shadow-lg text-white cursor-pointer bg-gradient-to-r ${purpleGradient} flex-shrink-1 hover:bg-sky-700`} >
              {response}
          </div>
        </div>
      }
    </>
  )
}