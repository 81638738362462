import React from 'react'
import PersonIcon from '@mui/icons-material/Person';

function UserEnquiry({enquiry, onClick, display='left'}) {

  return (
    <>
      { display=="left" ?

        <div className='flex flex-row justify-start gap-3 mb-4'>
            <div className='flex justify-center items-center w-7 h-7 p-1 rounded-full border-[1px] border-black shadow-2xl flex-shrink-0'>
              <PersonIcon className='h-full w-full'/>
          </div>

          <div onClick={() => onClick ? onClick() : null} className="cursor-pointer bg-gray-200 p-3 rounded-md ml-2 border-gray-300 border-[1px] drop-shadow-lg flex-shrink-1 hover:bg-black hover:text-white" >
              {enquiry}
          </div>
        </div>  

        : 
        <div className='flex flex-row justify-end gap-3 mb-6 '>

          <div className={`p-3 rounded-md ml-2 border-gray-300 border-[1px] drop-shadow-lg flex-shrink-1 text-black`}>
            {enquiry}
          </div>

          <div className='flex justify-center items-center w-7 h-7 p-1 rounded-full border-[1px] border-black shadow-2xl flex-shrink-0'>
            <PersonIcon className='h-full w-full'/>
          </div>

        </div>
      }
    </>
  )
}

export default UserEnquiry; 