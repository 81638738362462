import { UserContext } from './ProtectedRoutes';
import React, { useContext } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { purpleGradient } from 'constants/css';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function Navbar({toggleMenu}) {
  const {user, setUser} = useContext(UserContext);
  return (
    <div className={`flex h-16 w-full items-center justify-between px-8 bg-gradient-to-r ${purpleGradient} text-white text-lg shrink-0`}>
      <div onClick={toggleMenu} className="flex justify-center items-center w-10 h-10 cursor-pointer	 rounded hover:bg-gray-100 hover:text-black">
        <MenuIcon />
      </div> 

      <div className="">
        AI Chat Bot
      </div>

      <div className="flex flex-row gap-2 justify-center items-center">
          <AccountCircleIcon />
          {user.email ? <div>{user.email}</div> : <div>User not exist</div>}
      </div>

    </div>
  )
}

export default Navbar;