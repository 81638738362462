import { useContext, useState } from "react";
import { loginFields } from "constants/formFields";
import Input from "./Input";
import { loginLink, serverLink } from "constants/serverLink";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "@mui/material";

const fields = loginFields;
let fieldState = {};
fields.forEach((field) => (fieldState[field.id] = ""));

export default function Login({ setLoadingTrue, setLoadingFalse }) {
    const navigate = useNavigate();

    const [loginState, setLoginState] = useState(fieldState);

    const validateForm = () => {
        if (!loginState.email) {
            toast.error("Username is required");
            return false;
        }
        if (!loginState.password) {
            toast.error("Password is required");
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        setLoadingTrue();
        e.preventDefault();
        if (!validateForm()) {
            setLoadingFalse();
            return;
        }

        const formDetails = new URLSearchParams();
        formDetails.append("username", loginState.email);
        formDetails.append("password", loginState.password);

        try {
            const response = await fetch(loginLink, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: formDetails,
            });

            if (response.ok) {
                const data = await response.json();
                document.cookie = `token=${data.access_token}`;
                setLoadingFalse();
                navigate("/");
            } else {
                const errorData = await response.json();
                toast.error(errorData.detail || "Authentication failed!");
                setLoadingFalse();
            }
        } catch (error) {
            toast.error("An error occurred. Please try again later.");
            setLoadingFalse();
        }
    };

    const handleChange = (e) => {
        setLoginState({ ...loginState, [e.target.id]: e.target.value });
    };

    return (
        <div className="shadow-2xl flex justify-center items-center flex-col border-tb-2 border-black rounded-r-xl bg-white h-[30rem] w-[29rem]">
            <h2 className="max-w-[70%] text-3xl mb-12 text-center font-bold">
                Login
            </h2>
            <form onSubmit={handleSubmit}>
                <div>
                    {fields.map((field) => (
                        <Input
                            key={field.id}
                            handleChange={handleChange}
                            value={loginState[field.id]}
                            id={field.id}
                            name={field.name}
                            type={field.type}
                            required={field.isRequired}
                            placeholder={field.placeholder}
                            customClass="w-80"
                        />
                    ))}
                </div>
                <div className="flex justify-center w-full mt-3">
                    <button
                        type="submit"
                        className="text-lg bg-black text-white px-5 py-1 rounded-xl mt-2 h-10 w-full"
                        onSubmit={handleSubmit}
                    >
                        Login
                    </button>
                </div>
            </form>
        </div>
    );
}
