import React, { useContext, useEffect, useState } from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar";
import { UserContext } from "components/ProtectedRoutes";
import Navbar from "components/Navbar";
import axios from "axios";
import { serverLink } from "constants/serverLink";

function Home() {
    // Hide sidebar state
    const [hideSidebar, setHideSidebar] = useState(
        sessionStorage.getItem("sidebar")
    );

    // State to toggle sidebar on or off and save to session data
    const toggleMenu = () => {
        if (sessionStorage.getItem("sidebar") === "true") {
            sessionStorage.setItem("sidebar", "false");
            setHideSidebar("false");
        } else {
            sessionStorage.setItem("sidebar", "true");
            setHideSidebar("true");
        }
    };

    return (
        <div className="flex flex-col h-full w-full overflow-hidden">
            <div className="shrink-0 w-full">
                <Navbar toggleMenu={toggleMenu} />
            </div>
            <div className="flex flex-row w-full h-full overflow-y-hidden relative clear-both">
                <Sidebar hideSidebar={hideSidebar} />
                <Outlet />
            </div>
        </div>
    );
}

export default Home;
