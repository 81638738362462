import React, { useEffect, useState } from "react";
import SidebarItem from "./SidebarItem";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation, useNavigate } from "react-router-dom";
import { purpleGradient } from "constants/css";

import HomeIcon from "@mui/icons-material/Home";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ChatIcon from "@mui/icons-material/Chat";
import HistoryIcon from "@mui/icons-material/History";

function Sidebar({ hideSidebar }) {
    const constantIconClass = "h-5 w-5 mr-2";

    const sidebarItems = [
        {
            to: "/",
            name: "Home",
            icon: <HomeIcon className={constantIconClass} />,
            alt: "HomeIcon",
            customClass: "",
        },
        {
            to: "/chatbot",
            name: "Chatbot",
            icon: <ChatIcon className={constantIconClass} />,
            alt: "ChatbotIcon",
            customClass: "",
        },
        {
            to: "/Knowledge",
            name: "Knowledge",
            icon: <FileCopyIcon className={constantIconClass} />,
            alt: "FileCopyIcon",
            customClass: "",
        },
        {
            to: "/chathistory",
            name: "Chat History",
            icon: <HistoryIcon className={constantIconClass} />,
            alt: "HistoryIcon",
        },
    ];
    const [active, setActive] = useState();
    const location = useLocation();

    useEffect(() => {
        const getActiveFromURL = () => {
            const pathname = location.pathname;
            const activeItem = sidebarItems.find(
                (item) => item.to === pathname
            );
            if (activeItem) {
                setActive(activeItem.name);
            }
        };

        getActiveFromURL();
    }, [location.pathname]);

    const navigate = useNavigate();

    var delete_cookie = function (name) {
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    };

    const handleLogout = (e) => {
        delete_cookie("token");
        navigate("/login");
    };

    return (
        <div
            className={
                hideSidebar === "true"
                    ? "hidden transition-transform"
                    : "flex flex-col h-full w-full max-w-[16rem] border-r-2 border- shadow-xl bg-gray-100 p-6 justify-between transition-transform max-h-full"
            }
        >
            <div>
                {sidebarItems.map((eachitem) => (
                    <SidebarItem
                        key={eachitem.name}
                        to={eachitem.to}
                        name={eachitem.name}
                        icon={eachitem.icon}
                        alt={eachitem.alt}
                        customClass={
                            active === eachitem.name
                                ? eachitem.customClass +
                                  ` bg-gradient-to-r ${purpleGradient} text-white `
                                : eachitem.customClass
                        }
                    />
                ))}
            </div>

            <div
                onClick={handleLogout}
                className={`flex items-center px-4 py-6 rounded-md text-gray-600 hover:bg-black hover:text-white`}
            >
                <div className="flex justify-left items-center h-full w-full text-md cursor-pointer">
                    <LogoutIcon className="h-5 w-5 mr-2" />
                    <div className="text-md">Log Out</div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
