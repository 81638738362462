import LoginLogo from "components/Login/LoginLogo";
import Login from "components/Login/Login";
import { useState } from "react";
import SpinnerEffect from "components/SpinnerEffect";

export default function LoginPage() {
  const [loading, setLoading] = useState(false);
  const setLoadingTrue = () => setLoading(true);
  const setLoadingFalse = () => setLoading(false);
  return (
    loading ? <SpinnerEffect /> :
    <div className="bg-gray-200 flex flex-col md:flex-row justify-center items-center min-h-screen w-full p-4">
      <LoginLogo />
      <Login setLoadingTrue={setLoadingTrue} setLoadingFalse={setLoadingFalse}/>
    </div>
  );
}
