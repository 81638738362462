import React, { useEffect, useState } from "react";
import ChatHistory from "./ChatHistory";
import ChatProcessing from "./ChatProcessing";

function ViewConversation({ enquiry, trigger }) {
    const [selectedEnquiry, setSelectedEnquiry] = useState(null);

    const handleSelectedEnquiry = (data) => {
        console.log(data);
        setSelectedEnquiry(data);
    };

    return (
        <>
            {<div className="flex flex-col items-center">
                <div className="w-[90dvw] max-w-[1100px] h-[70dvh] max-h-[700px] grid grid-cols-[1fr_1fr] gap-6">
                    <ChatHistory
                        setAction={handleSelectedEnquiry}
                        enquiry={enquiry}
                        trigger={trigger}
                    />
                    <ChatProcessing selectedEnquiry={selectedEnquiry} />
                </div>
            </div>}
        </>
    );
}

export default ViewConversation;
