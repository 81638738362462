const constantClass = " rounded-md px-3 py-2 mb-4 h-11 bg-gray-100 border-2";

export default function Input({
  handleChange,
  value,
  labelText,
  labelFor,
  id,
  name,
  type,
  isRequired = false,
  placeholder,
  customClass = "",
}) {
  return (
    <div>
      <label htmlFor={labelFor} className="">
        {labelText}
      </label>

      <input
        onChange={handleChange}
        value={value}
        id={id}
        name={name}
        type={type}
        required={isRequired}
        className={customClass + constantClass}
        placeholder={placeholder}
      />
    </div>
  );
}
