import BotResponse from "./BotResponse";
import UserEnquiry from "./UserEnquiry";

export default function ChatHistory({ enquiry, setAction, trigger }) {
    // const [enquiryData, setEnquiryData] = useState();
    const handleClick = (eachEnquiry) => {
        setAction(eachEnquiry);
    };

    return (
        <>
            <div className="h-full w-full flex flex-col p-2 overflow-auto">
                <div className="text-center text-2xl font-bold mb-5 shrink-0  drop-shadow-sm">
                    Chat History
                </div>

                <div className="flex flex-col border-[1px] border-black rounded-xl p-4 h-full overflow-auto">
                    {enquiry && enquiry.length != 0 ? (
                        enquiry.map((eachEnquiry, index) => (
                            <div key={index}>
                                {eachEnquiry.enquiry ? (
                                    <UserEnquiry
                                        enquiry={eachEnquiry.enquiry}
                                        onClick={() => handleClick(eachEnquiry)}
                                    />
                                ) : (
                                    <></>
                                )}
                                {eachEnquiry.response ? (
                                    <BotResponse
                                        response={eachEnquiry.response}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        ))
                    ) : (
                        <div className="flex justify-center items-center w-full h-full">
                            No Enquiry
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
