import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

export default function Modal({
    open,
    onClose,
    children,
    outsideClose = true,
    className,
}) {
    return (
        <>
            {open && (
                <div
                    onClick={outsideClose ? onClose : null}
                    className={`
          fixed inset-0 flex items-center justify-center transition-colors shadow-2xl
           ${open ? "visible bg-black/60" : "invisible"}
        `}
                >
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className={`
          bg-white rounded-xl shadow p-6 transition-all  ${className}
          ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}
          `}
                    >
                        <button
                            onClick={onClose}
                            className="absolute top-2 right-2 p-1 rounded-lg
              text-gray-400 bg-white hover:bg-gray-50
              hover:text-gray-600"
                        >
                            <CloseIcon />
                        </button>

                        {children}
                    </div>
                </div>
            )}
        </>
    );
}
