import React from 'react'
import Chat from './Chat'
import ChatProcessing from './ChatProcessing'

function TestConversation({botURL}) {
  return (
    <div className='flex flex-col items-center'>
      {console.log(botURL)}
        <div className="w-[90dvw] max-w-[1100px] h-[70dvh] max-h-[700px] grid grid-cols-[1fr_1fr] gap-6">
            <Chat botURL={botURL}/>
            <ChatProcessing selectedEnquiry={""} />
        </div>
    </div>
  )
}

export default TestConversation;