const loginFields = [
  {
    labelText: "email",
    labelFor: "email",
    id: "email",
    type: "email",
    isRequired: true,
    placeholder: "Email",
  },
  {
    labelText: "password222",
    labelFor: "password",
    id: "password",
    type: "password",
    isRequired: true,
    placeholder: "Password",
  },
];

export { loginFields };
