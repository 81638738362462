import React from 'react'

function PageNotFound() {
  return (
    <div className='h-full w-full text-center flex items-center justify-center text-4xl'>
        404 Page Not Found 
    </div>
  )
}

export default PageNotFound