import axios, { Axios } from "axios";
import { addChatbotLink, getChatbotLink } from "constants/serverLink";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserContext } from "./ProtectedRoutes";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

function CreateNewChatbot() {
    const [chatbotData, setChatbotData] = useState([]); // [chatbot_id, chatbot_name, chatbot_url, chatbot_builder_id, timestamp]

    const fetchChatbotData = async () => {
        try {
            const response = await fetch(getChatbotLink);

            if (!response.ok) {
                throw new Error(`Backend Server Error: ${response.status}`);
            }
            const data = await response.json();
            if (Array.from(data.data).length === 0) {
                throw new Error("No Data Found!");
            }
            setChatbotData(Array.from(data.data));
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchChatbotData();
    }, []);
    const [chatbotName, setChatbotName] = useState("");
    const [chatbotURL, setChatbotURL] = useState("");
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();

    const handleSave = async () => {
        console.log("run");
        if (chatbotName === "" || chatbotURL === "") {
            toast.error("Please fill in all fields");
            return;
        }
        const urlExists = chatbotData.some(
            (chatbot) => chatbot.chatbot_url === chatbotURL
        );
        const nameExists = chatbotData.some(
            (chatbot) => chatbot.chatbot_name === chatbotName
        );
        if (urlExists) {
            toast.error("Chatbot URL already exists");
            return;
        }
        if (nameExists) {
            toast.error("Chatbot Name already exists");
            return;
        }

        const data = {
            chatbot_name: chatbotName,
            chatbot_url: chatbotURL,
            chatbot_builder_id: user.user_id,
        };

        try {
            // Validate and potentially fix the URL
            let url = chatbotURL;
            if (!url.startsWith("http://") && !url.startsWith("https://")) {
                throw new Error(
                    'Invalid URL: URL must start with "http://" or "https://"'
                );
            }

            const urlObject = new URL(url);
            console.log(urlObject);

            const result = await fetch(
                `${urlObject.origin}/api/testConnection`,
                {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (!result.ok) {
                throw new Error(`HTTP error! status: ${result.status}`);
            }

            toast.success("Chatbot Works Great!");
            const result2 = await fetch(addChatbotLink, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ...data,
                    chatbot_url: urlObject.origin,
                }),
            });

            if (result2.ok) {
                sessionStorage.setItem("chatbotCreated", "true");
                window.location.reload();
            } else {
                throw new Error(
                    `Error adding chatbot: ${await result2.text()}`
                );
            }
        } catch (error) {
            console.error(error);
            toast.error(`Error: Failed to Connect Chatbot\n${error.message}`);
        }
    };

    return (
        <div className="flex flex-col h-full max-h-[600px] w-[600px] px-6">
            <div className="font-bold text-2xl text-left mb-4">
                Create New Chatbot
            </div>
            <div className="my-2 overflow-y-auto">
                <h2 className="text-lg mb-4">Chatbot Configuration</h2>
                <div className="mb-2 flex flex-row w-full">
                    <div className="flex flex-col w-3/10 p-2 min-w-[30%]">
                        <label
                            htmlFor="chatbotName"
                            className="text-base text-right"
                        >
                            Chatbot Name
                        </label>
                    </div>
                    <input
                        type="text"
                        id="chatbotName"
                        value={chatbotName}
                        className="border-2 rounded p-2 w-full"
                        onChange={(e) => setChatbotName(e.target.value)}
                    />
                </div>
                <div className="mb-2 flex flex-row w-full">
                    <div className="flex flex-col w-3/10 p-2 min-w-[30%]">
                        <label
                            htmlFor="chatbotURL"
                            className="text-base text-right"
                        >
                            Chatbot URL
                        </label>
                    </div>
                    <input
                        type="text"
                        id="chatbotURL"
                        value={chatbotURL}
                        className="border-2 rounded p-2 w-full"
                        onChange={(e) => setChatbotURL(e.target.value)}
                    />
                </div>
            </div>
            <Button
                variant="contained"
                style={{ marginLeft: "auto" }}
                // className="mx-6 h-10 p-4 rounded-lg border-2 bg-black text-white flex justify-center items-center ml-auto"
                onClick={handleSave}
            >
                Save
            </Button>
        </div>
    );
}

export default CreateNewChatbot;
