// import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { verifyLink } from "../constants/serverLink";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SpinnerEffect from "./SpinnerEffect";

export const UserContext = createContext(null);

const ProtectedRoutes = ({ children }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [auth, setAuth] = useState(false);
    const [user, setUser] = useState({});

    // var delete_cookie = function (name) {
    //     document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    // };

    // axios.defaults.withCredentials = true;
    useEffect(() => {
        async function verify() {
            try {
                function getCookieValue(name) {
                    const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
                        const [key, value] = cookie.split('=');
                        acc[key] = value;
                        return acc;
                    }, {});
                    return cookies[name] || null;
                }
                
                // Usage example
                const token = getCookieValue('token');

                if (token === null) {
                    throw new Error("No token found!");
                }
                
                const response = await fetch(verifyLink, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: JSON.stringify({ token: token.toString() }),
                });
                if (!response.ok) {
                    throw new Error("Authentication failed! Please try again!");
                }
                const data = await response.json();
                setUser({
                    user_id: data.user_id,
                    email: data.email,
                });
                setAuth(true);
            } catch (error) {
                setAuth(false);
                toast.error("Authentication failed!\nPlease Login!", error.message);
                // delete_cookie("token");
                navigate("/login");
            }
        }
        setLoading(true);
        verify();
        setLoading(false);
    }, [navigate]);

    return loading ? (
        <SpinnerEffect />
    ) : auth ? (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    ) : null;
};

export default ProtectedRoutes;
