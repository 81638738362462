// constants/serverLink.js

const server = process.env.REACT_APP_SERVER_URL;

export const verifyLink = server + "/api/verify-token";
export const loginLink = server + "/token";
export const fastapiFileUploadLink = server + "/api/knowledge/create";
export const fastapiClearKnowledgeLink = server + "/api/knowledge/clear";
export const deleteKnowledgeLink = server + "/api/knowledge/delete";
export const fastapiLLM = server + "/api/api/llm/";
export const fastapiCreateVectorStoreLink = server + "/api/vectorstore/create";
export const getKnowledgeLink = server + "/api/knowledge/get";
export const getConversation = server + "/api/conversation/get";
export const getConversationHistory = server + "/api/conversation/post";
export const getChatbotLink = server + "/api/chatbot/get";
export const deleteChatbotLink = server + "/api/chatbot/delete";
export const addChatbotLink = server + "/api/chatbot/add";
export const getChatbotKnowledge = server + "/api/knowledge/post";
export const getStatistic = server + "/api/statistics/get";
