import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { getConversation, getConversationHistory } from "constants/serverLink";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "components/Modal";
import ViewConversation from "components/Conversation/ViewConversation";
import { pageConfiguration } from "constants/css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localeEn from "dayjs/locale/en";
import "./MUIDataTable.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SpinnerEffect from "components/SpinnerEffect";

function ChatHistory() {
    const daysago = (postDate) => {
        dayjs.extend(relativeTime).locale(localeEn);
        var fromNowOn = dayjs(postDate).fromNow();
        return fromNowOn;
    };

    const [loading, setLoading] = useState(false);

    const columns = [
        {
            name: "conversation_id",
            label: "Conversation ID",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "user_id",
            label: "User",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "start_time",
            label: "Start Time",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return daysago(value);
                },
            },
        },
        {
            name: "end_time",
            label: "End Time",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return daysago(value);
                },
            },
        },
        {
            name: "conversation_id",
            label: "Function",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Tooltip title="View Chat History">
                            <IconButton
                                onClick={(e) => openConversation(e, value)}
                            >
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                    );
                },
            },
        },
    ];

    const [viewChatHistory, setViewChatHistory] = useState(false);
    const [enquiry, setEnquiry] = useState([]);

    const openConversation = async (e, value) => {
        try {
            if (await fetchConversationHistory(value)) {
                console.log("Conversation History: ", conversation);
                setViewChatHistory(true);
            }
        } catch (err) {
            toast.error(err.message);
        }
    };

    const fetchConversationHistory = async (value) => {
        setLoading(true);
        try {
            const response = await fetch(getConversationHistory, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ conversation_id: value.toString() }),
            });

            if (!response.ok) {
                throw new Error(`Backend Server Error: ${response.status}`);
            }
            const data = await response.json();
            if (Array.from(data.history).length === 0) {
                throw new Error("Unable to be viewed: \nNo Conversation History Found!");
            }
            setEnquiry(Array.from(data.history));
            setLoading(false);
            return true;
        } catch (err) {
            toast.error(err.message);
            setLoading(false);
            return false;
        }
    };

    const fetchConversation = async () => {
        setLoading(true);
        try {
            const response = await fetch(getConversation);

            if (!response.ok) {
                throw new Error(`Backend Server Error: ${response.status}`);
            }
            const data = await response.json();
            if (Array.from(data.conversation).length === 0) {
                throw new Error("No History Found!");
            }
            setConversation(Array.from(data.conversation));
            setLoading(false);
        } catch (err) {
            toast.error(err.message);
            setLoading(false);
            return false;
        }
    };

    const [conversation, setConversation] = useState([]);

    useEffect(() => {
        fetchConversation();
    }, []);

    const options = {
        filterType: "checkbox",
        eleviation: 0,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        selectableRows: "none",
    };

    const getMuiTheme = () =>
        createTheme({
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontFamily: "poppins",
                            justifyContent: "1px",
                            paddingLeft: "1px",
                            fontWeight: "bold",
                        },
                    },
                },
            },
        });

    return (
        loading ? <SpinnerEffect /> : 
        <div className={`${pageConfiguration}`}>
            <div className="flex flex-col justify-start">
                <h1 className="text-2xl font-bold">Chat History</h1>
                <p className="pt-2">
                    In this page we can view the previous chat history and
                    backend processing
                </p>
            </div>
            <div className="pt-10 ">
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={"Chat History"}
                        data={conversation}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>
            <Modal
                open={viewChatHistory}
                onClose={() => setViewChatHistory(false)}
            >
                <div className="flex flex-col items-center">
                    <ViewConversation
                        enquiry={enquiry}
                        trigger={viewChatHistory}
                    />
                </div>
            </Modal>
        </div>
    );
}

export default ChatHistory;
