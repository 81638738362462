import React, { useEffect, useState, useRef } from "react";
import UserEnquiry from "./UserEnquiry";
import BotResponse from "./BotResponse";
import SendIcon from "@mui/icons-material/Send";

function Chat({ botURL }) {
    const [query, setQuery] = useState({ "": "" });
    const [conversation, setConversation] = useState([]);
    const [userQuery, setUserQuery] = useState("");
    const [mode, setMode] = useState("naive_rag_without_memory");
    const [clientId] = useState(() => Date.now());
    const [requestCount, setRequestCount] = useState(0);
    const websocketRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        let url = new URL(botURL);
        let urlWithPort = url.hostname + ":" + url.port;
        websocketRef.current = new WebSocket(
            `ws://${urlWithPort}/ws/${clientId}`
        );
        console.log(`ws://${urlWithPort}/ws/${clientId}`);

        websocketRef.current.onmessage = (event) => {
            console.log(event.data);
            const data = JSON.parse(event.data);
            console.log(data);
            setConversation((prev) => {
                console.log(prev);
                const newConversation = [...prev];
                console.log(newConversation);
                newConversation[newConversation.length - 1].bot +=
                    data.response;
                console.log(newConversation);
                return newConversation;
            });
        };

        // Cleanup function
        // return () => {
        //     websocket.close();
        // };
    }, [botURL, clientId]);

    useEffect(() => {
        containerRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [conversation]);

    const handleQueryInputChange = (e) => {
        setQuery({ ...query, [e.target.name]: e.target.value });
    };

    const sendMessage = () => {
        if (query[""] === "") return;
        setConversation((prev) => [...prev, { user: query[""], bot: "" }]);

        if (websocketRef.current) {
            websocketRef.current.send(
                JSON.stringify({
                    query: query[""],
                    identifier: requestCount,
                    mode: mode,
                })
            );
        }

        setRequestCount((prev) => prev + 1);
        setQuery({ "": "" });
    };

    return (
        <>
            <div className="h-full w-full flex flex-col p-2 overflow-y-auto">
                <div className="text-center text-2xl font-bold mb-5 shrink-0 drop-shadow-sm">
                    Chat
                </div>
                <div>Your Socket ID: {clientId}</div>
                <select
                    className="bg-white text-black py-2 px-2 rounded mb-3 border-2 border-black"
                    onChange={(e) => setMode(e.target.value)}
                    value={mode}
                >
                    <option value="naive_rag_without_memory">
                        Naive RAG (Without Memory)
                    </option>
                    <option value="advanced_rag_with_memory_query_rewrite">
                        Advanced RAG (With Memory)
                    </option>
                    <option value="multi_query_rag_with_memory">
                        Multi Query RAG (With Memory)
                    </option>
                </select>

                <div className="flex flex-col border-[1px] border-black rounded-xl h-full w-full justify-between overflow-y-auto">
                    <div className="p-4 overflow-y-auto">
                        {conversation.map((message, index) => (
                            <React.Fragment key={index}>
                                <UserEnquiry
                                    enquiry={message.user}
                                    display="right"
                                />
                                <BotResponse
                                    response={message.bot}
                                    display="left"
                                />
                            </React.Fragment>
                        ))}
                        <div ref={containerRef} />
                    </div>

                    <div className="flex flex-row justify-between w-full border-black border-t-2 p-3 items-center">
                        <div className="w-full">
                            <input
                                className="border-gray-500 border-[1px] p-2 rounded w-full"
                                type="text"
                                name=""
                                value={query[""]}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") sendMessage();
                                }}
                                onChange={handleQueryInputChange}
                                placeholder="Type your message here"
                            />
                        </div>
                        <div
                            className="hover:bg-gray-100 rounded-none p-2 cursor-pointer"
                            onClick={sendMessage}
                        >
                            <SendIcon />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Chat;
