import React from "react";

function PageDescription({ title, description }) {
    return (
        <>
            <h1 className="text-2xl font-bold">{title}</h1>

            <p className="pt-2">
                {description}
            </p>
        </>
    );
}

export default PageDescription;
