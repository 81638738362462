import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import axios from "axios";
import {
    deleteKnowledgeLink,
    fastapiClearKnowledgeLink,
    getKnowledgeLink,
} from "constants/serverLink";
import FileForm from "components/FileForm";
import { toast } from "react-toastify";
import Modal from "components/Modal";
import { pageConfiguration } from "constants/css";
import PageDescription from "components/PageDescription";
import { Button, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "./MUIDataTable.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SpinnerEffect from "components/SpinnerEffect";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function Knowledge() {
    // Loading useState
    const [loading, setLoading] = useState(true);
    const iconButtonStyle = "px-5 py-2 rounded border-2";
    const [confirmDeleteAllKnowledge, setConfirmDeleteAllKnowledge] =
        useState(false);
    const [deleteSelectedKnowledgeDialog, setDeleteSelectedKnowledgeDialog] =
        useState(false);

    const columns = [
        {
            name: "knowledge_id",
            label: "Knowledge ID",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "chatbot_builder_id",
            label: "Created By",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "created_at",
            label: "Creation Time",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "knowledge_id",
            label: "Function",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="flex flex-row gap-2">
                            <Tooltip title="Delete">
                                <IconButton
                                    onClick={(e) => {
                                        setSelectedKnowlede(value);
                                        setDeleteSelectedKnowledgeDialog(true);
                                    }}
                                    className={iconButtonStyle}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    );
                },
            },
        },
    ];

    const [knowledge, setKnowledge] = useState([]);

    useEffect(() => {
        fetchData();
    }, [confirmDeleteAllKnowledge]);

    const fetchData = () => {
        setLoading(true);
        fetch(getKnowledgeLink, {
            credentials: "include",
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Backend Server Error!");
                }
                return res.json();
            })
            .then((data) => {
                setKnowledge(data);
            })
            .catch((err) => {
                toast.error(err);
            });
        setLoading(false);
    };

    const [selectedKnowledge, setSelectedKnowlede] = useState();

    const toggleDeleteSelectedKnowledgeDialog = () => {
        setDeleteSelectedKnowledgeDialog((prev) => !prev);
        fetchData();
    };

    const options = {
        filterType: "checkbox",
        eleviation: 0,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        selectableRows: "none",
        // rowsSelected: selectedRows,
        // onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
        //     setSelectedRows(rowsSelected);
        // },
        // onRowsDelete: (rowsDeleted, newData) => {
        //     // delete rows function here
        //     //
        //     console.log(rowsDeleted);
        //     console.log(newData);
        // },
    };

    const [newKnowledgeSetup, setNewKnowledgeSetup] = useState(false);

    const toggleNewKnowledgeSetup = () => {
        setNewKnowledgeSetup((prev) => !prev);
    };

    const closeNewKnowledgeSetup = () => {
        setNewKnowledgeSetup((prev) => !prev);
        fetchData();
    };

    const clearKnowledge = async (e) => {
        e.preventDefault();

        await axios
            .post(fastapiClearKnowledgeLink)
            .then((res) => {
                if (res.data === "Success") {
                    setKnowledge([]);
                    setConfirmDeleteAllKnowledge(false);
                    toast.success("Cleared All Knowledge!");
                } else {
                    console.log(res.data.Error);
                    toast.error("Error !");
                }
            })
            .catch((error) => {
                toast.error("Error Connect To Server!");
            });
    };

    const confirmDeleteSelectedKnowledge = async () => {
        try {
            const response = await fetch(deleteKnowledgeLink, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    knowledge_id: selectedKnowledge.toString(),
                }),
            });

            console.log(response)

            if (!response.ok) {
                throw new Error(`Backend Server Error: ${response.status}`);
            }
            const data = await response.json();
            console.log(data);
            if (data.Status !== "Success") {
                throw new Error("Delete Operation Failed");
            }
            toast.success("Successfully Deleted Selected Knowledge!");
            setLoading(false);
            toggleDeleteSelectedKnowledgeDialog();
        } catch (err) {
            toast.error(err.message);
            setLoading(false);
            toggleDeleteSelectedKnowledgeDialog();
        }
    };


    const getMuiTheme = () =>
        createTheme({
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontFamily: "poppins",
                            justifyContent: "1px",
                            paddingLeft: "1px",
                            fontWeight: "bold",
                        },
                    },
                },
            },
        });

    return loading ? (
        <SpinnerEffect />
    ) : (
        <div className={`${pageConfiguration}`}>
            <div className="flex flex-row justify-between">
                <div>
                    <PageDescription
                        title="Knowledge"
                        description="In this page we can view, add and delete Knowledge to be used in constructing the Vector Store"
                    />
                </div>
                <div className="gap-2 flex flex-col">
                    <Button
                        onClick={() => setConfirmDeleteAllKnowledge(true)}
                        variant="outlined"
                    >
                        Clear All Knowledge
                    </Button>

                    <Button
                        onClick={toggleNewKnowledgeSetup}
                        variant="outlined"
                    >
                        Add New Knowledge
                    </Button>
                </div>
            </div>

            <div className="mt-10 p-1 overflow-y-auto">
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={"Knowledge"}
                        data={knowledge}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>

            <FileForm
                knowledge={[...knowledge.map((k) => k.name)]}
                newKnowledgeSetup={newKnowledgeSetup}
                closeNewKnowledgeSetup={closeNewKnowledgeSetup}
            />

            <Modal
                open={confirmDeleteAllKnowledge}
                onClose={() => setConfirmDeleteAllKnowledge(false)}
            >
                <div className="flex flex-col items-center p-6">
                    <h1 className="text-xl font-bold">
                        Are you sure you want to clear all knowledge?
                    </h1>
                    <div className="flex flex-row mt-5 gap-5">
                        <button
                            onClick={() => setConfirmDeleteAllKnowledge(false)}
                            className="px-5 py-2 rounded border-2"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={clearKnowledge}
                            className="px-5 py-2 rounded border-2"
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </Modal>


            <Dialog
                open={deleteSelectedKnowledgeDialog}
                onClose={toggleDeleteSelectedKnowledgeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to delete the Knowledge?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Caution: This operation cannot be revoke.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleDeleteSelectedKnowledgeDialog}>
                        Cancel
                    </Button>
                    <Button onClick={confirmDeleteSelectedKnowledge} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
{/*             

            <Dialog
                open={deleteSelectedKnowledgeDialog}
                onClose={toggleDeleteSelectedKnowledgeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogTitle id="alert-dialog-title">
                        Do you sure to delete the selected knowledge?
                    </DialogTitle>

                    <DialogContentText id="alert-dialog-description">
                        This action cannot be undone. Please proceed with
                        caution.
                    </DialogContentText>
                </DialogContent>

                <DialogContent>
                    <DialogActions>
                        <Button onClick={toggleDeleteSelectedKnowledgeDialog}>
                            Cancel
                        </Button>
                        <Button
                            onClick={confirmDeleteSelectedKnowledge}
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog> */}
        </div>
    );
}

export default Knowledge;
