import React from "react";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { deleteChatbotLink, getChatbotLink } from "constants/serverLink";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "components/Modal";
import { pageConfiguration } from "constants/css";
import TestConversation from "components/Conversation/TestConversation";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip,
} from "@mui/material";
import CreateNewChatbot from "components/CreateNewChatbot";
import PageDescription from "components/PageDescription";
import { IconButton } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";
import KnowledgeSelection from "components/KnowledgeSelection";
import "./MUIDataTable.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SpinnerEffect from "components/SpinnerEffect";

function Chatbot() {
    const viewChatbot = (e, value) => {};

    const testChatbot = (e, value) => {
        toggleTestBot();
    };

    // Edit Chatbot Modal
    const [editChatbotModal, setEditChatbotModal] = useState(false);

    const toggleEditChatbotModal = () => {
        setEditChatbotModal((prev) => !prev);
    };

    let currentDate = new Date();

    const columns = [
        {
            name: "chatbot_name",
            label: "Chatbot Name",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "chatbot_builder_name",
            label: "Chatbot Builder Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "knowledge_selection_count",
            label: "Knowledge Count",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "timestamp",
            label: "Creation Day",
            options: {
                filter: false,
                sort: true,
                customElements: (value) => {
                    const dataDate = dayjs(value);
                    // Example: showing the difference in days from the current date
                    const difference = dataDate.diff(currentDate, "day");
                    return (
                        <span>
                            {dataDate.format("YYYY-MM-DD")} ({difference} days
                            ago)
                        </span>
                    );
                },
            },
        },
        {
            name: "chatbot_id",
            label: "Function",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="flex flex-row gap-2">
                            <Tooltip title="Edit">
                                <IconButton
                                    onClick={(e) => {
                                        setSelectedChatbot(value);
                                        toggleEditChatbotModal();
                                    }}
                                    className={iconButtonStyle}
                                >
                                    <ModeEditIcon />
                                </IconButton>
                            </Tooltip>

                            {/* <Tooltip title="View">
                                <IconButton
                                    onClick={(e) => {
                                        setSelectedChatbot(value);
                                        viewChatbot(e, value);
                                    }}
                                    className={iconButtonStyle}
                                >
                                    <VisibilityIcon />
                                </IconButton>
                            </Tooltip> */}

                            {/* <Tooltip title="Test">
                                <IconButton
                                    onClick={(e) => {
                                        setSelectedChatbot(value);
                                        testChatbot(e, value);
                                    }}
                                    className={iconButtonStyle}
                                >
                                    <PlayArrowIcon />
                                </IconButton>
                            </Tooltip> */}

                            <Tooltip title="Delete">
                                <IconButton
                                    onClick={(e) => {
                                        setSelectedChatbot(value);
                                        toggleDeleteChatbotModal();
                                    }}
                                    className={iconButtonStyle}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    );
                },
            },
        },
    ];

    // Loading if system is still retrieving information from database
    const [loading, setLoading] = useState(false);

    //  Delete Chatbot Modal

    const [deleteChatbotModal, setDeleteChatbotModal] = useState(false);

    const toggleDeleteChatbotModal = () => {
        setDeleteChatbotModal((prev) => !prev);
    };

    const deleteChatbotButton = async () => {
        try {
            const response = await fetch(deleteChatbotLink, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    chatbot_id: selectedChatbot.toString(),
                }),
            });

            console.log(response);

            if (!response.ok) {
                throw new Error(`Backend Server Error: ${response.status}`);
            }
            sessionStorage.setItem("chatbotDeleted", "true");
            window.location.reload();
        } catch (err) {
            toast.error(err.message);
        }
    };

    const [chatbot, setChatbot] = useState([]);

    // Use Effect to retrieve data from the server
    useEffect(() => {
        if (sessionStorage.getItem("chatbotDeleted") === "true") {
            toast.success("Chatbot Deleted Successfully");
            sessionStorage.removeItem("chatbotDeleted");
        }
        if (sessionStorage.getItem("chatbotCreated") === "true") {
            toast.success("Chatbot Created Successfully");
            sessionStorage.removeItem("chatbotCreated");
        }
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await fetch(getChatbotLink);

            if (!response.ok) {
                throw new Error(`Backend Server Error: \n${response.status}`);
            }
            const data = await response.json();
            if (Array.from(data.data).length === 0) {
                throw new Error("No Chatbot Data Found!");
            }
            setChatbot(Array.from(data.data));
        } catch (err) {
            if (err.message === "No Chatbot Data Found!") {
            } else {
                toast.error(err.message);
            }
        }
        setLoading(false);
    };

    const options = {
        filterType: "checkbox",
        eleviation: 0,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        selectableRows: "none",
    };

    const [newChatbotSetup, setNewChatbotSetup] = useState(false);

    const toggleNewChatbotSetup = () => {
        setNewChatbotSetup((prev) => !prev);
    };

    const closeNewChatbotSetup = () => {
        toggleNewChatbotSetup();
        fetchData();
    };

    const [testBot, setTestBot] = useState(false);

    const toggleTestBot = () => {
        setTestBot((prev) => !prev);
    };

    const iconButtonStyle = "px-5 py-2 rounded border-2";
    const buttonStyle =
        "px-4 py-2 font-bold hover:bg-black hover:text-white rounded-md border-2 shadow";

    const [createNewChatbot, setCreateNewChatbot] = useState(false);

    const toggleCreateNewChatbot = () => {
        setCreateNewChatbot((prev) => !prev);
    };

    const [selectedChatbot, setSelectedChatbot] = useState();

    const getChatbotUrlById = (id) => {
        const filteredChatbots = chatbot.filter((c) => c.chatbot_id === id);
        return filteredChatbots.length > 0
            ? filteredChatbots[0].chatbot_url
            : null;
    };

    const getMuiTheme = () =>
        createTheme({
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontFamily: "poppins",
                            justifyContent: "1px",
                            paddingLeft: "1px",
                            fontWeight: "bold",
                        },
                    },
                },
            },
        });

    return loading ? (
        <SpinnerEffect />
    ) : (
        <div className={`${pageConfiguration}`}>
            <div className="flex flex-row justify-between pb-0">
                <div>
                    <PageDescription
                        title="Chatbot"
                        description="In this page we can view, configure and delete chatbot"
                    />
                </div>
                <div>
                    <Button
                        variant="outlined"
                        onClick={() => toggleCreateNewChatbot()}
                    >
                        Add New Chatbot
                    </Button>
                </div>
            </div>

            <div className="pt-10">
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={"Chatbots"}
                        data={chatbot}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>

            <KnowledgeSelection
                open={editChatbotModal}
                onClose={() => toggleEditChatbotModal}
                selectedChatbot={selectedChatbot}
                chatbotURL={getChatbotUrlById(selectedChatbot)}
            />

            <Dialog
                open={deleteChatbotModal}
                onClose={() => toggleDeleteChatbotModal()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to delete the Chatbot?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Caution: This operation cannot be revoke.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => toggleDeleteChatbotModal()}>
                        Cancel
                    </Button>
                    <Button onClick={() => deleteChatbotButton()} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            
            <Modal
                open={testBot}
                onClose={() => toggleTestBot()}
                outsideClose={false}
            >
                <TestConversation botURL={getChatbotUrlById(selectedChatbot)} />
            </Modal>

            <Modal
                open={createNewChatbot}
                onClose={() => toggleCreateNewChatbot()}
                outsideClose={false}
            >
                <CreateNewChatbot />
            </Modal>
        </div>
    );
}

export default Chatbot;
