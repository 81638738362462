import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { toast } from "react-toastify";
import {
    fastapiCreateVectorStoreLink,
    getChatbotKnowledge,
    getKnowledgeLink,
} from "constants/serverLink";
import axios from "axios";
import { Checkbox, FormControlLabel } from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import SpinnerEffect from "./SpinnerEffect";

function KnowledgeSelection({ open, onClose, selectedChatbot, chatbotURL, mode = "edit" }) {
    const [loading, setLoading] = useState(false)
    // Knowledge Option in checkbox
    const [knowledgeOptions, setKnowledgeOptions] = useState([]);

    // Selected Knowledge
    const [selectedKnowledge, setSelectedKnowledge] = useState([]);

    const fetchKnowledgeOption = async () => {
        setLoading(true);
        try {
            const response = await fetch(getKnowledgeLink, {
                credentials: "include",
            });

            if (!response.ok) {
                throw new Error(`Backend Server Error: ${response.status}`);
            }
            setKnowledgeOptions(await response.json());
            setLoading(false);
        } catch (err) {
            toast.error(err.message);
            setLoading(false);
        }
    }

    const fetchCurrentKnowledge = async () => {
        setLoading(true);
        try {
            const response = await fetch(getChatbotKnowledge,{
                method: "POST",
                body: JSON.stringify({
                    chatbot_id: selectedChatbot
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (!response.ok) {
                throw new Error(`Backend Server Error: ${response.status}`);
            }
            const data = await response.json();
            // Extract only the knowledge_id values
            const knowledgeIds = data.data.map(item => item.knowledge_id);
            setSelectedKnowledge(knowledgeIds);
            setLoading(false);
        } catch (err) {
            toast.error(err.message);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (open) {
            if (mode === "edit") {
                fetchKnowledgeOption();
            }
            fetchCurrentKnowledge();

        } else {
            setSelectedKnowledge([]);
            setKnowledgeOptions([]);
        }
    }, [open, selectedChatbot, mode]);

    const handleCheckboxChange = (id) => {
        console.log(id);
        setSelectedKnowledge((prev) =>
            prev.includes(id)
                ? prev.filter((knowledgeId) => knowledgeId !== id)
                : [...prev, id]
        );
    };

    const submitEditButton = () => {
        setLoading(true);
        try {
            console.log(selectedKnowledge, selectedChatbot);
            console.log(chatbotURL+"/api/vectorstore/create");
            let url = new URL(chatbotURL+"/api/vectorstore/create");
            axios
                .post(url, {
                    chatbot_id: selectedChatbot,
                    knowledge_id: selectedKnowledge,
                })
                .then((res) => {
                    if (res.data.status == "Success") {
                        toast.success("Vector Store Created Successfully");
                        setLoading(false);
                        window.location.reload();
                    } else {
                        setLoading(false);
                        toast.error(res.data.message);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    toast.error(err);
                    return;
                });
            // window.location.reload();
            // toast.success("Successful initiate vector store request, please wait for a while. We will inform you the result once process done");
        } catch (error) {
            setLoading(false);
            toast.error(error);
            return;
        }
    };

    return (

        loading ? <SpinnerEffect /> :
        <Modal
            open={open}
            onClose={onClose()}
            className="w-fit min-w-[600px] max-w-[600px] max-h-[80%] h-fit"
        >
            <div className="flex flex-col items-center justify-center p-6 h-full w-full">
                <h1 className="text-lg font-bold mb-8">
                    {mode === "edit"
                        ? `Please Select Knowledge For Chatbot ${selectedChatbot} :`
                        : `Selected Knowledge`}
                </h1>
                <div className="flex flex-col max-w-full w-full h-full max-h-[400px] border-2 rounded p-4">
                    <div className="overflow-y-auto overflow-x-hidden ">
                        {knowledgeOptions.map((option) => (
                            <FormControlLabel
                                className="hover:bg-gray-300 hover:text-black whitespace-normal w-full p-2 rounded break-all"
                                key={option.knowledge_id}
                                control={
                                    <Checkbox
                                        id={option.id}
                                        checked={selectedKnowledge.includes(
                                            option.knowledge_id
                                        )}
                                        onChange={() =>
                                            handleCheckboxChange(
                                                option.knowledge_id
                                            )
                                        }
                                        color="primary"
                                    />
                                }
                                label={
                                    <div className=" max-w-full break-all">
                                        {option.name}
                                    </div>
                                }
                            />
                        ))}
                    </div>
                </div>
                <div className="flex flex-row mt-5 gap-5">
                    {mode === "edit" ? (
                        <>
                            <button
                                onClick={onClose()}
                                className="px-5 py-2 rounded border-2 "
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => submitEditButton()}
                                className="px-5 py-2 rounded border-2"
                            >
                                Confirm
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                onClick={onClose()}
                                className="px-5 py-2 rounded border-2 "
                            >
                                Close
                            </button>
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
}

export default KnowledgeSelection;
