import { fastapiFileUploadLink } from "constants/serverLink";
import React, { useContext, useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DescriptionIcon from "@mui/icons-material/Description";

import { useDropzone } from "react-dropzone";
import { UserContext } from "./ProtectedRoutes";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "@mui/icons-material/Delete";
import SpinnerEffect from "./SpinnerEffect";
import Modal from "./Modal";
import { IconButton, Tooltip } from "@mui/material";

function FileForm({ knowledge, newKnowledgeSetup, closeNewKnowledgeSetup }) {
    // Existing knowledge names
    const [existingKnowledgeName, setExistingKnowledgeName] = useState([]);
    useEffect(() => {
        setExistingKnowledgeName(knowledge);
    }, [knowledge]);

    // Make sure new list of file and fileNames
    // when the modal is created
    useEffect(() => {
        setFiles([]);
        setFileNames([]);
    }, []);

    // Get user context to retrieve the user id
    const { user, setUser } = useContext(UserContext);

    // File and fileNames state
    const [files, setFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);

    // Loading state
    const [loading, setLoading] = useState(false);

    // Handle file name input change
    const handleFileNameInputChange = (index, newName) => {
        setFileNames((previousFileNames) => {
            const updatedFileNames = [...previousFileNames];
            updatedFileNames[index] = newName;
            return updatedFileNames;
        });
        setDuplicate((previousDuplicate) => {
            const updatedDuplicate = [...previousDuplicate];
            updatedDuplicate[index] = isNameDuplicate(index, newName);
            return updatedDuplicate;
        });
    };

    // Handle submit function
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (files.length === 0) {
            toast.error("Please Upload a File!");
        } else if (duplicate.includes(true)) {
            toast.error("Please Fix the Name Error!");
        } else {
            const formData = new FormData();

            files.forEach((f) => {
                formData.append("files_upload", f);
            });

            fileNames.forEach((fn) => {
                formData.append("fileName", fn);
            });

            formData.append("created_by", user.user_id);

            setLoading(true);

            try {
                const response = await fetch(fastapiFileUploadLink, {
                    method: "POST",
                    body: formData,
                });
                if (response.ok) {
                    setExistingKnowledgeName((prev) => [...prev, ...fileNames]);
                    toast.success("Successfully Uploaded!");
                    window.location.reload();
                } else {
                    toast.error(response);
                    setLoading(false);
                    setFiles([]);
                    setFileNames([]);
                    setDuplicate([]);
                }
            } catch (error) {
                toast.error(error);
                setLoading(false);
            }
        }
    };

    const getFileNameWithoutExtension = (filename) => {
        const parts = filename.split(".");
        parts.pop();
        return parts.join(".");
    };

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            "text/csv": [".csv"],
            "application/msword": [".doc"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
            "application/epub+zip": [".epub"],
            "application/vnd.oasis.opendocument.text": [".odt"],
            "application/pdf": [".pdf"],
            "application/vnd.ms-powerpoint": [".ppt"],
            "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
            "text/tab-separated-values": [".tsv"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
            "text/plain": [".txt"]
        },
        onDrop: (acceptedFiles) => {
            setFiles([...files, ...acceptedFiles]);

            const newFileNames = acceptedFiles.map((file) =>
                getFileNameWithoutExtension(file.name)
            );
            setFileNames([...fileNames, ...newFileNames]);

            const newDuplicates = newFileNames.map((name) =>
                firstIsNameDuplicate(name)
            );
            setDuplicate([...duplicate, ...newDuplicates]);
        },
    });

    const [duplicate, setDuplicate] = useState([]);

    const firstIsNameDuplicate = (name) => {
        var result = null;
        if (fileNames.length > 1) {
            result =
                existingKnowledgeName.includes(name) ||
                fileNames.includes(name);
        } else {
            result = existingKnowledgeName.includes(name);
        }
        return result;
    };

    const isNameDuplicate = (index, name) => {
        var result = null;
        if (fileNames.length > 1) {
            result =
                existingKnowledgeName.includes(name) ||
                fileNames
                    .slice(0, index)
                    .concat(fileNames.slice(index + 1))
                    .includes(name);
        } else {
            result = existingKnowledgeName.includes(name);
        }
        return result;
    };

    const handleDeleteFile = (index) => {
        setFiles((previousFiles) =>
            previousFiles.filter((_, i) => i !== index)
        );
        setFileNames((previousFileNames) =>
            previousFileNames.filter((_, i) => i !== index)
        );
        setDuplicate((previousDuplicate) =>
            previousDuplicate.filter((_, i) => i !== index)
        );
    };

    const fileDescription = files.map((file, index) => (
        <div
            key={index}
            className="flex flex-row items-center justify-left w-full p-4 mb-2"
        >
            <Tooltip title="Delete">
                <IconButton onClick={() => handleDeleteFile(index)}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            <div className="flex flex-row items-center justify-left mx-5 border-2 w-full max-w-xl p-4 rounded-xl">
                <DescriptionIcon className="mr-5" />
                <div className="flex flex-col">
                    <div className="">
                        {file.path} ({file.size} bytes)
                    </div>
                    <input
                        type="text"
                        value={fileNames[index]}
                        onChange={(e) =>
                            handleFileNameInputChange(index, e.target.value)
                        }
                        className="border-2 rounded px-2 py-1 w-full max-w-full"
                    ></input>
                    {duplicate[index] ? (
                        <span className="text-red-500">
                            Name already exists
                        </span>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    ));

    return (
        <>
            {loading ? (
                <SpinnerEffect />
            ) : (
                <Modal
                    open={newKnowledgeSetup}
                    onClose={() => closeNewKnowledgeSetup()}
                    outsideClose={false}
                >
                    <div className="flex flex-col w-full max-w-2xl h-fit max-h-[80dvh] rounded-2xl p-10 bg-white justify-center items-center">
                        <div
                            {...getRootProps({
                                className:
                                    "cursor-pointer	 dropzone flex flex-col items-center justify-center h-full max-w-md mx-auto p-8 border-2 border-dashed border-gray-300 rounded-lg hover:border-gray-500 hover:bg-gray-200 transition-colors shadow-xl bg-white mb-5",
                            })}
                        >
                            <input {...getInputProps()} />
                            <CloudUploadIcon className="shadow-2xl" />
                            <p className="pt-5 text-base">
                                Drag And drop some files here, or click to
                                select files
                            </p>
                        </div>
                        {fileDescription.length > 0 ? (
                            <h1 className="text-xl mb-3">Files</h1>
                        ) : (
                            <></>
                        )}
                        <div className="flex flex-row flex-wrap	justify-center items-center overflow-auto relative max-h-[60dvh]">
                            {fileDescription}
                        </div>

                        <button
                            onClick={handleSubmit}
                            className="bg-yellow-100 px-4 py-2 rounded border-2 border-black hover:bg-green-400 mt-3"
                        >
                            Upload
                        </button>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default FileForm;
