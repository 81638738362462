import { pageConfiguration } from "constants/css";
import React, { Children, useEffect, useState } from "react";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { Chart as ChartJS } from "chart.js/auto";
import { Bar, Line, Pie } from "react-chartjs-2";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { NativeSelect } from "@mui/material";
import { toast } from "react-toastify";
import { getStatistic } from "constants/serverLink";
import SpinnerEffect from "components/SpinnerEffect";
import SmsIcon from '@mui/icons-material/Sms';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import TokenIcon from '@mui/icons-material/Token';


const statisticComponent = [
    {
        title: "Total Conversations",
        key: "conversation_count",
        icon: <QuestionAnswerIcon />,
    },
    {
        title: "Total Enquiries",
        key: "enquiry_count",
        icon: <SmsIcon />,
    },
    {
        title: "Total Chatbot Responses",
        key: "chatbot_response_count",
        icon: <ThumbUpAltIcon />,
    },
    {
        title: "Total Unanswered Enquiries",
        key: "unanswered_enquiry_count",
        icon: <ThumbDownAltIcon />,
    },
    {
        title: "Total Chatbots",
        key: "chatbot_count",
        icon: <QuestionAnswerIcon />,
    },
    {
        title: "Total Knowledge",
        key: "knowledge_count",
        icon: <DescriptionIcon />,
    },
    {
        title: "Total Knowledge Selection",
        key: "knowledge_selection_count",
        icon: <AssignmentTurnedInIcon />,
    },
    {
        title: "Total Token Usage",
        key: "token_count",
        icon: <TokenIcon />,
    },
    {
        title: "Average Response Time",
        key: "average_response_time",
        icon: <AccessTimeFilledIcon />,
    },
];

// const ChatbotData = [
//     {
//         chatbot_name: "Chatbot 1",
//         total_conversation: 5000,
//         total_question_answered: 600,
//         total_unanswered_question: 300,
//         response_time: 0.5,
//     },
//     {
//         chatbot_name: "Chatbot 2",
//         total_conversation: 5000,
//         total_question_answered: 600,
//         total_unanswered_question: 300,
//         response_time: 0.5,
//     },
// ];

// const chatbotDropdownChoice = [
//     {
//         label: "All",
//         value: "-1",
//     },
//     {
//         label: "Chatbot 1",
//         value: "122328",
//     },
//     {
//         label: "Chatbot 2",
//         value: "122326",
//     },
// ];

// const dataRangeDropdown = [
//     {
//         label: "Daily",
//         value: 1,
//     },
//     {
//         label: "Week",
//         value: 7,
//     },
//     {
//         label: "Month",
//         value: 30,
//     },
//     {
//         label: "Year",
//         value: 365,
//     },
// ];

function Dashboard() {
    const [loading, setLoading] = useState(false);
    const [statistics, setStatistics] = useState({
        conversation_count: "Loading...",
        enquiry_count: "Loading...",
        chatbot_response_count: "Loading...",
        unanswered_enquiry_count: "Loading...",
        chatbot_count: "Loading...",
        knowledge_count: "Loading...",
        knowledge_selection_count: "Loading...",
        token_count: "Loading...",
        average_response_time: "Loading...",
    });
    // const [chatbotDropdown, setChatbotDropdown] = React.useState("-1");
    // const handleChatbotDropdownChange = (event) => {
    //     setChatbotDropdown(event.target.value);
    // };
    // const [rangeDropdown, setRangeDropdown] = React.useState("365");
    // const handleRangeDropdownChange = (event) => {
    //     setRangeDropdown(event.target.value);
    // };

    const fetchStatistics = async () => {
        setLoading(true);
        try {
            const response = await fetch(getStatistic);
            if (!response.ok) {
                throw new Error("Failed to fetch statistics");
            }
            const data = await response.json();
            setStatistics(data.statistics);
            setLoading(false);
        } catch (error) {
            toast.error(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchStatistics();
    }, []);

    return (
        loading ? <SpinnerEffect /> :
        <div className={`${pageConfiguration} overflow-y-auto`}>
            <div className="flex flex-col justify-start">
                <div className="flex justify-between gap-2 items-center">
                    <div className="flex flex-col">
                        <h1 className="text-2xl font-bold">Dashboard</h1>
                        <p className="pt-2">
                            In this page we can view the dashboard for the metrics in the system.
                        </p>
                    </div>
                    {/* <div className="flex gap-4">
                        <Box sx={{ minWidth: 100, maxWidth: 150 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    Chatbot
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={chatbotDropdown}
                                    label="Chatbot"
                                    onChange={handleChatbotDropdownChange}
                                >
                                    {chatbotDropdownChoice.map((item) => (
                                        <MenuItem value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ minWidth: 100, maxWidth: 150 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    Data Range
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={rangeDropdown}
                                    label="Data Range"
                                    onChange={handleRangeDropdownChange}
                                >
                                    {dataRangeDropdown.map((item) => (
                                        <MenuItem value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </div> */}
                </div>
                <div className="max-w-full mt-8 flex flex-row flex-wrap gap-10 justify-between ">
                    {statisticComponent.map((item, index) => (
                        <Card
                            key={index}
                            title={item.title}
                            value={statistics[item.key]}
                            onClick={() => null}
                        >
                            {item.icon}
                        </Card>
                    ))}
                </div>
                {/* <SimpleCard title="Pie Chart">
                    <Pie
                        className="w-10 h-10"
                        data={{
                            labels: ChatbotData.map(
                                (data) => data.chatbot_name
                            ),
                            datasets: [
                                {
                                    label: "Count",
                                    data: ChatbotData.map(
                                        (data) => data.total_conversation
                                    ),
                                },
                            ],
                        }}
                    />
                </SimpleCard> */}
            </div>
        </div>
    );
}

function Card({ title, value, onClick, children }) {
    return (
        <div
            className="p-4 w-full min-w-[300px] h-40 max-w-[400px] flex-wrap rounded-xl bg-white border-[1.5px] border-[hsla(0,0%,80%)] border-b-gray-200 shadow-[hsla(0,0%,0%,0.1)_5px_5px_5px] flex flex-row justify-center items-center "
            onClick={onClick()}
        >
            <div className="w-2/6 flex justify-center items-center">
                {children}
            </div>
            <div className="flex flex-col w-4/6">
                <div className="">
                    <h1 className="font-bold text-3xl">{value}</h1>
                    <h1 className="">{title}</h1>
                </div>
            </div>
        </div>
    );
}

function SimpleCard({ title, customStyle, children }) {
    return (
        <div className="p-4 w-full h-full flex-wrap rounded-xl bg-white border-[1.5px] border-[hsla(0,0%,80%)] border-b-gray-200 shadow-[hsla(0,0%,0%,0.1)_5px_5px_5px] flex flex-col justify-center items-center ">
            <h1 className="font-bold text-3xl">{title}</h1>
            {children}
        </div>
    );
}

export default Dashboard;
