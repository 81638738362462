import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginPage from "views/LoginPage";
import Home from "views/Home";
import Chatbot from "views/Chatbot";
import Dashboard from "views/Dashboard";
import Knowledge from "views/Knowledge";
import ProtectedRoutes from "components/ProtectedRoutes";
import ChatHistory from "views/ChatHistory";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageNotFound from "views/PageNotFound";
// import WorkSpace from "temp/WorkSpace/WorkSpace";
// import {ThemeProvider} from '@mui/styles';

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <ProtectedRoutes>
                <Home />
            </ProtectedRoutes>
        ),
        children: [
            {
                index: true,
                element: <Dashboard />,
            },
            {
                path: "/chatbot",
                element: <Chatbot />,
            },
            {
                path: "/Knowledge/*",
                element: <Knowledge />,
            },
            {
                path: "/chatHistory",
                element: <ChatHistory />,
            },
        ],
    },
    {
        path: "/login",
        element: <LoginPage />,
    },
    {
        path: "*",
        element: <PageNotFound />,
    },
]);

function App() {
    return (
        <>
            <div style={{ height: "100dvh", width: "100%" }}>
                <RouterProvider router={router} />
                <ToastContainer
                    position="top-center"
                    autoClose={6000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </>
    );
}

export default App;
